export const NEWS_SAGA_GET_DATA = "NEWS_SAGA_GET_DATA";
export const NEWS_SAGA_GET_BY_ID = "NEWS_SAGA_GET_BY_ID";
export const NEWS_SAGA_CREATE = "NEWS_SAGA_CREATE";
export const NEWS_SAGA_UPDATE = "NEWS_SAGA_UPDATE";
export const NEWS_SAGA_UPDATE_ACTIVE = "NEWS_SAGA_UPDATE_ACTIVE";
export const NEWS_SAGA_DELETE = "NEWS_SAGA_DELETE";

export const NEWS_REDUCE_SET_DATA = "NEWS_REDUCE_SET_DATA";
export const NEWS_REDUCE_SET_CURRENT = "NEWS_REDUCE_SET_CURRENT";
export const NEWS_REDUCE_IS_SHOW = "NEWS_REDUCE_IS_SHOW";
export const NEWS_REDUCE_CHECKOUT_REQUEST = "NEWS_REDUCE_CHECKOUT_REQUEST";