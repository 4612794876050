import { getNews, getNewsById, updateActiveNews, showWindow } from "domain/actions/news.action";
import ActionsColumn from "pages/views/components/table/action.column";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { ScrollPanel } from "primereact/scrollpanel";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { toDate } from "utils/toDate.util";
import CreateNewsWindows from "../windows/create.news.windows";
import UpdateNewsWindows from "../windows/update.news.windows";
import DeleteNewsWindows from "../windows/delete.news";
import { INews } from "domain/types/news.type";

export const toStatusTemplate = (rowData: any) => <span className={`rest-status rest-status-${rowData.isActive ? 'active' : 'notActive'}`}>{rowData.isActive ? "Активно" : "Не активно"}</span>
export const ListNews: React.FC = (props: any) => {
  const { list, loading, getNews, getNewsById, showWindow, updateActiveNews } = props;
  const [ item, setItem ] = useState<INews | undefined>(undefined);

  const isMobile = useMediaQuery({
    query: '(max-width: 768px)'
  })

  useEffect(() => {
    getNews()
  }, [getNews])

  const handleCreate = () => showWindow('create', true);
  const handleEdit = (id: string) => { 
    getNewsById(id)
    // showWindow('update', true)
  };
  const handleDelete = (item: INews) => { 
    setItem(item)
    showWindow('delete', true)
  };
  const handleIsActive = (item: INews) => updateActiveNews(item.id, item)
  const handleRefresh = () => getNews();

  let menu = (item: INews) => [
    {
      label: 'Редактирование', 
      icon: 'pi pi-fw pi-pencil', 
      command:()=> handleEdit(item.id)
    },
    {
      label: item.isActive ? 'Деактивировать' : 'Активировать', 
      icon: item.isActive ? 'pi pi-fw pi-ban' : 'pi pi-fw pi-check-circle', 
      command:()=> handleIsActive(item)
    },
    {
      label: 'Удаление', 
      icon: 'pi pi-fw pi-trash', 
      command:()=> handleDelete(item)
    },
  ]

  const action = (rowData: any) =>
    <ActionsColumn menuItems={menu(rowData)} element={rowData} />

  return (
    <div className="content">
    
      <ScrollPanel style={{ width: '100%', height: 'calc(100vh - 214px)' }}>
    
        <div className="col-12 pl-0 flex flex-wrap align-items-baseline">
          <div className="flex flex-wrap">
            <Button label="Добавить" onClick={handleCreate} className="p-button-raised p-button-success mr-1"/>
            <Button label="Обновить" onClick={handleRefresh} className="p-button-raised p-button-info p-button-text ml-1"/>
          </div>
        </div>
    
        <div className="card">
          <DataTable value={list} 
            scrollable={!isMobile} scrollHeight={!isMobile ? "calc(100vh - 310px)" : ""} 
            responsiveLayout="stack" breakpoint="768px" loading={loading}
            className="table-mobile-rows" stripedRows 
          >
            <Column field="header" header="Заголовок"
              sortable sortField="header"
              style={{cursor: "default"}}
            ></Column>

            <Column field="isActive" header="Статус" 
              sortable sortField="isActive" style={{ maxWidth: '10rem' }}
              body={toStatusTemplate}
            ></Column>
                  
            <Column field="createdAt" header="Дата создания" 
              sortable sortField="createdAt" style={{ maxWidth: '12rem' }}
              body={(rowData)=>toDate(rowData.createdAt)}
            ></Column>
    
            <Column body={action} style={{ maxWidth: '5rem' }}></Column>
          </DataTable>
        </div>
    
      </ScrollPanel>

      <CreateNewsWindows />
      <UpdateNewsWindows />
      <DeleteNewsWindows item={item} />
    </div>
  );
}

const mapStateToProps = (state: any) => ({
  list: state.news.data,
  loading: state.news.request.loading
})

export default connect(mapStateToProps, {getNews, getNewsById, updateActiveNews, showWindow})(ListNews);