import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { showWindow, updateNews } from 'domain/actions/news.action';

import { Dialog } from 'primereact/dialog';
import { Form } from 'react-final-form';
import { showError } from 'domain/actions/norr.action';
import FormContentNews from './component/form.content';

const UpdateNewsWindows: React.FC = (props: any) => {
  const { current, visible, showWindow, updateNews } = props;
  const [ content, setContent ] = useState('');
  const [ file, setFile ] = useState(0);

  const handleClose = () => showWindow('update', false);
  const onSubmit = (values: any) => {
    values['content'] = content;
    updateNews(values.id, values, file)
  };

  useEffect( () => {
    if(current) setContent(current.content)
  }, [current] )

  useEffect(() => {
    if(!visible) setFile(0)
  }, [visible])

  return (
    <Dialog header="Изменение новости" visible={visible} style={{ minWidth: '50vw' }} onHide={handleClose}>
      <Form
        onSubmit={onSubmit}
        initialValues={current}
        styles={{minWidth: '320px'}}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit} className="col-12 p-0">
            <FormContentNews 
              file={file} 
              setFile={setFile} 
              content={content} 
              setContent={setContent} 
              handleClose={handleClose} 
              currentPreview={current.preview}
            />
          </form>
        )}
      />

    </Dialog>
  );
};

const mapStateToProps = (state: any) => ({
  visible: state.news.windows.update,
  current: state.news.current
})
export default connect(mapStateToProps, { showWindow, updateNews, showError } )(UpdateNewsWindows);