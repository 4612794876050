import React, { useEffect } from "react";
import { connect } from "react-redux";
import { 
  getRestaurantById, 
  getBidUpdateById, 
  showWindow, 
  completeBidUpdate, 
  denyBidUpdate 
} from 'domain/actions/restaurants.action';

import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import BidUpdateContent from "./bid.update.content";

interface IProps{
  currentId: string | null;
}

export const BidUpdateWin: React.FC<IProps> = (props: any) => {
  const { currentId, visible, restaurant, bid } = props;
  const { showWindow, getBidUpdateById, getRestaurantById, completeBidUpdate } = props;

  useEffect(() => {
    if(currentId) getBidUpdateById(currentId)
  }, [getBidUpdateById, currentId])

  useEffect(() => {
    if(bid) getRestaurantById(bid.restaurantId)
  },[bid, getRestaurantById])

  const handleClose = () => showWindow('bid_update', false);

  const onSubmit = () => completeBidUpdate();
  const onDeny = () => handleClose();

  return (
    <>
      <Dialog header="Заявка на редактирование" visible={visible} style={{minWidth: '383px', maxWidth: '1024px'}} onHide={handleClose}>
        <BidUpdateContent bid={bid} restaurant={restaurant}/>

        <div className="col-12 p-0 flex flex-wrap justify-content-between">
          <div className="col-12 md:col-6 xl:col-4">
            <Button label="Отменить" className="col-12 p-button-outlined p-button-secondary" onClick={onDeny}/>
          </div>
          <div className="col-12 md:col-6 xl:col-4">
            <Button label="Применить" className="col-12 p-button-raised p-button-success " onClick={onSubmit}/>
          </div>
          
        </div>
      </Dialog>
    </>
  );
}

const mapStateToProps = (state: any) => ({
  restaurant: state.restaurants.current.restaurant,
  bid: state.restaurants.current.update,
  visible: state.restaurants.windows.bid_update
})

export default connect(mapStateToProps, { 
  showWindow, 
  getBidUpdateById, 
  getRestaurantById,
  completeBidUpdate,
  denyBidUpdate
})(BidUpdateWin);