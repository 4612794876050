import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { getUserById, showWindow  } from 'domain/actions/users.action';

import { Dialog } from 'primereact/dialog';
import { Divider } from 'primereact/divider';
import { toDate } from 'utils/toDate.util';
import { toRoleTemplate } from '../components/role';
import { toRestaurantsChips } from '../components/restaurants';
import { isHTTPS } from 'utils/regexp';

interface IProps{
  currentId: string | null;
}

const UserDialog: React.FC<IProps> = (props: any) => {
  const { currentId, visible, showWindow, getUserById, current } = props;
  
  useEffect(() => {
    if(currentId) getUserById(currentId)
  }, [getUserById, currentId])

  const handleClose = () => showWindow('views', false);

  if(!current) return <></>;

  return (
    <Dialog 
      header="Просмотр пользователя"
      visible={visible}
      onHide={handleClose}
    >
      <div className="flex flex-wrap align-items-center justify-content-center">
        <div>
          <img 
            alt={current.fio} 
            src={`${isHTTPS(current.avatar) 
              ? current.avatar 
              : current.avatar 
                ? '/api/files/' + current.avatar
                : '/api/admin/files/default'
              }`}
            width={120}
            height={120}
            style={{ verticalAlign: 'middle', borderRadius: '50%' }} 
            className="m-2"
          />
        </div>
        
        <div className='text-center md:text-left'>
          <p className="m-0"> <strong>{current?.name ? `${current?.surname ? current.surname : ''} ${current?.name}` : 'Не указано'} </strong></p> 
          <p className="m-0">( 
            <i className="px-1">tel: <a href={`tel:${current.phone}`}>{current.phone}</a></i>
            {current.email ? <span>, <i className="px-1">email: <a href={`mailto:${current.email}`}>{current.email}</a></i></span> : <></>} 
          )</p>
          <p className="m-0"> <strong>{current.city.name}</strong> </p>
          {toRoleTemplate(current.role)}
          <p className="m-0">Дата рождения: <strong> {current.birthday ? toDate(current.birthday) : <i>-Не указано-</i> } </strong></p>
          <p className="m-0">Дата регистрации: <strong> <i>{toDate(current.createdAt)} </i> </strong> </p>
        </div>
        
      </div>

      <div className="col-12 p-0 flex">
        <p><b>Комментарий:</b> <i>{current?.comment?.length ? current?.comment : " - "}</i> </p>
      </div>

      {current?.restaurant.length > 0 && (
        <>
          <Divider />
          <div className="flex flex-wrap justify-content-around" style={{maxWidth: '500px'}}>
            <h3 className="col-12 block text-center"><u>Привязанные заведения</u></h3>
            {toRestaurantsChips(current.restaurant)}
          </div>
        </>
      )}
      

    </Dialog>
  );
};

const mapStateToProps = (state: any) => ({
  current: state.users.current,
  visible: state.users.windows.views,
})
export default connect(mapStateToProps, { getUserById, showWindow } )(UserDialog);