import React from "react";
import { Editor } from "primereact/editor";

interface IProps {
  value: string;
  setValue: (value: string) => void;
}

export const EditorContent: React.FC<IProps> = (props: IProps) => {
  const {value, setValue} = props;
      
  return (
    <div className="card">
      <Editor value={value} onTextChange={(e: any) => setValue(e.htmlValue)} style={{ height: '320px', maxWidth: '1024px' }} />
    </div>
  );
}

export default EditorContent;