import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { showWindow, updateRestaurant } from 'domain/actions/restaurants.action';
import { getHashtags } from "domain/actions/hashtags.action";
import { showError } from "domain/actions/norr.action";


import { ScrollPanel } from 'primereact/scrollpanel';
import { TabView, TabPanel } from 'primereact/tabview';
import { Form } from "react-final-form";
import { Button } from "primereact/button";

import { updateRestaurantFormat, formationData, validate } from "./initialData";
import MainForm from "../components/main";
import GalleryForm from "../components/gallery";
import SocialForm from "../components/social";
import HashtagsForm from '../components/hashtags';
import WorkingForm from "../components/working";
import GalleriaImage from "../components/gallery.images";
import ContactsForm from "../components/contacts";
import KitchenForm from "../components/kitchen";
import SingleUploadImage from "components/upload/single.upload";
import { IPhone } from "domain/types/restaurants.type";
import WarningActiveCouponWin from "../warning.active.coupon.window";

export const UpdateContent: React.FC = (props: any) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [phones, setPhone] = useState<IPhone[]>([]);
  const [data, setData] = useState<any>({});
  const [saveValues, setSaveValues] = useState<any>({});
  const [files, setFiles] = useState<any>([]);
  const [file, setFile] = useState(0);
  const { current, showError, showWindow, updateRestaurant } = props;
  let refLogo = useRef<any>(null);
  let refGallery = useRef<any>(null);
  
  const handleCancel = (event: any) => {
    event.preventDefault();
    showWindow('update', false);
  };
  const handleAccess = () => {
    const isCheck = false;
    updateRestaurant( saveValues, isCheck )
    showWindow('warningActiveTask', false)
  }

  const onSubmit = (values: any) => {
    values.logotype = file
    const validMsg = validate(values);
    if(validMsg) return showError(validMsg)
    const _values = updateRestaurantFormat( values, files, file, phones );
    setSaveValues(_values)
    const isCheck = true;
    updateRestaurant( _values, isCheck )
  };

  useEffect(() =>setData(formationData(current)), [current]) // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() =>setPhone(current.addPhones), []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Form
        onSubmit={onSubmit}
        initialValues={data}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit} >
            <TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)} scrollable>
              <TabPanel header="Основная информация" >
                <MainForm >
                  <SingleUploadImage 
                    ref={refLogo} 
                    label={"Загрузить логотип"} 
                    onSelectFIle={setFile} 
                    file={file}
                    currentImg={data ? data.logo : null}
                  />
                </MainForm>
              </TabPanel>

              <TabPanel header="Контакты и Адрес" >
                <ContactsForm 
                  city={`г. ${current?.city}, ${current?.address}`} 
                  phones={phones} 
                  setPhone={setPhone}                    
                />
              </TabPanel>

              <TabPanel header="Галерея">
                <ScrollPanel style={{maxHeight: '660px'}}>
                  <h3 className="m-2">Текущая галерея</h3>
                  <GalleriaImage images={current ? current.gallery : []} />
                      
                  <h3 className="m-2">Новые фотографии</h3>
                  <GalleryForm setFiles={setFiles} files={files} ref={refGallery}/>
                </ScrollPanel>
              </TabPanel>

              <TabPanel header="Кухня">
                <KitchenForm />
              </TabPanel>
              <TabPanel header="Социальные сети">
                <SocialForm />
              </TabPanel>
                <TabPanel header="Часы работы">
                <WorkingForm />
              </TabPanel>
                <TabPanel header="Хештеги">
                <HashtagsForm />
              </TabPanel>
                
            </TabView>
            
            <div className="col-12 p-0 flex flex-wrap mt-1 mb-2">
              <div className="col-12 md:col-6 xl:col-3">
                <Button label="Отмена" className="col-12 p-button-raised p-button-plain p-button-text" onClick={handleCancel}/>
              </div>
              <div className="col-12 md:col-6 xl:col-3">
                <Button label="Изменить" className="col-12 p-button-raised p-button-success "/>
              </div>
            </div>
          </form>
        )}
      />

      <WarningActiveCouponWin onAccess={handleAccess} />
    </>
  );
}

const mapStateToProps = (state: any) => ({
  visible: state.restaurants.windows.add,
  current: state.restaurants.current.restaurant
})

export default connect(mapStateToProps, { 
  showWindow, getHashtags, showError, updateRestaurant
})(UpdateContent);