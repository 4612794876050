import React, { useRef } from 'react';
import { Field } from 'react-final-form';
import { Button } from 'primereact/button';
import { CheckboxField, TextField } from 'components/form/fields';
import { composeValidators, requiredValidator } from 'components/form/validators';
import EditorContent from 'components/editor';
import SingleUploadImage from 'components/upload/single.upload';

interface IProps {
  file: any,
  currentPreview?: string | undefined,
  setFile: (file: any) => void,
  content: string,
  setContent: (text: string) => void,
  handleClose: () => void
}

const FormContentNews: React.FC<IProps> = (props: IProps) => {
  const { file, currentPreview, setFile, content, setContent, handleClose } = props;
  let refGallery = useRef<any>(null);

  return (
    <>
      <div className='col-12 flex flex-column md:flex-row'>
        <div className="p-2 flex justify-content-center md:justify-content-start">
          <SingleUploadImage 
            ref={refGallery} 
            label={"Загрузить фото-превью"} 
            onSelectFIle={setFile} 
            file={file}
            currentImg={currentPreview ? currentPreview : undefined}
          />
        </div>
        <div className='' style={{flex: '1 1'}}>
          <div className="col-12">
            <Field
              validate={composeValidators(requiredValidator)}
              name="header"
              label="Заголовок:"
              render={TextField}
              settings={{
                placeholder: "Название заголовка"
              }}
            />
          </div>
          <div className="col-12" >
            <Field
              name="isAdultContent"
              render={CheckboxField}
              inputId='isAdultContentHTML'
            />
            <label htmlFor="isAdultContentHTML" className="ml-2" style={{ fontSize: '12px' }}>
              Контент 18+
            </label>
          </div>
        </div>
      </div>
            
      <div className="hidden" >
        <Field
          name="isActive"
          render={CheckboxField}
          inputId='isActiveHTML'
        />
        <label htmlFor="isActiveHTML" className="ml-2" style={{ fontSize: '12px' }}>
          Активировать
        </label>
      </div>

      <EditorContent value={content} setValue={setContent}  />

      <div className="mt-1 col-12 flex flex-wrap justify-content-between">
        <Button className="col-12 md:col-5 xl:col-4 p-button-danger mb-2 md:mb-0" label="Отмена" onClick={ (e) => {
          e.preventDefault();
            handleClose();
          }}/>
        <Button className="col-12 md:col-5 xl:col-4 p-button-success" label="Принять" type="submit"/>
      </div>
    </>
  );
};

export default FormContentNews;