import React, { useEffect } from "react";
import { connect } from "react-redux";
import * as actions from "domain/actions/billing.action";

import ActionsColumn from "pages/views/components/table/action.column";
// import ImageColumn from "pages/views/components/table/image.column";
import { Button } from "primereact/button";
import { Chip } from 'primereact/chip';
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { ScrollPanel } from "primereact/scrollpanel";
import { useMediaQuery } from "react-responsive";
import { toStatusOrder, toTypeOrder } from "pages/views/controllers/toStatus";
import { toDate } from "utils/toDate.util";


export const Orders: React.FC = (props: any) => {
  const { showWindow, getOrders, getOrder, setArchive } = props;
  const { orders, loading } = props;
  const isMobile = useMediaQuery({
    query: '(max-width: 768px)'
  })

  useEffect(() => getOrders(), []) // eslint-disable-line react-hooks/exhaustive-deps

  const handleNewReceipt = () => showWindow(`new`, true);
  const handleRefresh = () => getOrders();
  const handlePreview = (id: string) => {
    getOrder(id);
    showWindow('view', true);
  }
  const handleArchive = (id: string) => setArchive(id)

  let menu = (item: { id: string, order: {state: string}; }) => [
    {
      label: 'Просмотр счета', 
      icon: 'pi pi-fw pi-info-circle', 
      command:()=> handlePreview(item.id)
    },
    {
      label: 'Убрать в архив', 
      icon: 'pi pi-fw pi-info-circle', 
      command:()=> handleArchive(item.id),
      disabled: item.order.state !== 'reject' && item.order.state !== 'confirm'
    },
  ]

  const action = (rowData: any) => <ActionsColumn menuItems={menu(rowData)} element={rowData} />

  const nameTemplate = (restaurant: any, state: string) => (
    <span >
      <strong>{restaurant?.name}</strong>
      {restaurant?.deletedAt && 
          <div style={{color: 'red'}}>
            {`(Заведение удалено - от ${toDate(restaurant?.deletedAt)})`}
          </div>
      } - 
      <i>{restaurant?.address}</i> 
      {state === 'confirm' 
        ? <Chip label="Активирован" icon="pi pi-check" className="ml-2 chip-confirm" style={{ fontSize: '10px' }}/>
        : state === 'started' 
          ? <Chip label="Запуск назначен" icon="pi pi-bell" className="ml-2 chip-warning" style={{ fontSize: '10px' }}/>
          : <Chip label="Не активирован" icon="pi pi-times" className="ml-2 chip-error" style={{ fontSize: '10px' }}/> 
      }
    </span>
  )

  const toStatusTemplate = (status: string) => <span className={`task-status task-status-${status}`}>{toStatusOrder(status)}</span>
  const toTypePayment = (type: string) => <span className={`task-status task-type-${type}`}>{toTypeOrder(type)}</span>
  
  return (
    <>
      <div className="content">

        <ScrollPanel style={{ width: '100%', height: 'calc(100vh - 214px)' }}>

          <div className="col-12 pl-0 flex flex-wrap align-items-baseline">
            <div className="flex flex-wrap">
              <Button label="Новый счет" onClick={handleNewReceipt} className="p-button-raised p-button-success mr-1"/>
              <Button label="Обновить" onClick={handleRefresh} className="p-button-raised p-button-info p-button-text ml-1"/>
            </div>
          </div>

          <div className="card">
            <DataTable value={orders} 
              scrollable={!isMobile} scrollHeight={!isMobile ? "calc(100vh - 310px)" : ""} 
              responsiveLayout="stack" breakpoint="768px" loading={loading}
              className="table-mobile-rows" stripedRows 
            >
              <Column field="restaurant.name" header="Заведение"
                body={(rowData) => nameTemplate(rowData?.restaurant, rowData?.state)}
                sortable sortField="restaurant.name"
                style={{cursor: "default"}}
              ></Column>
              
              <Column field="order.order.state" header="Статус" body={(rowData)=>toStatusTemplate(rowData.order.state)} 
                sortable sortField="order.state"
                style={{ maxWidth: '7rem', cursor: "default" }}
              ></Column>

              <Column field="order.typePayment" header="Тип оплаты" 
                body={(rowData)=>toTypePayment(rowData.order.typePayment)}
                style={{ maxWidth: '7rem', cursor: "default" }}
              ></Column>
              <Column body={action} style={{ maxWidth: '5rem' }}></Column>
            </DataTable>
          </div>

        </ScrollPanel>
      </div>
      
      
    </>
    
  );
}

const mapStateToProps = (state: any) => ({
  orders: state.billing.orders,
  loading: state.users.request.loading
})

export default connect(mapStateToProps, {...actions})(Orders);