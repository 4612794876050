import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { 
  showWindowQuestion, 
  createQuestion, 
  updateQuestion,
  clearQuestion
} from 'domain/actions/secretShopper.action';

import { Dialog } from 'primereact/dialog';
import { IQuestion } from 'domain/types/secretShopper.type';
import { Button } from 'primereact/button';
import { Field, Form } from 'react-final-form';
import { MaskField, SelectField, MultiSelectField, TextAreaField } from 'components/form/fields';
import { composeValidators, requiredValidator } from 'components/form/validators';
import { ICategory } from 'domain/types/restaurants.type';
import { ActionsBtn } from 'pages/views/components';
import QuestionTypesForm from './question.types.form';

export const categoriesQuestion = [
  {name: 'Основная информация', value: 'main'},
  {name: 'Вход', value: 'entry'},
  {name: 'Расчёт/прощание', value: 'farewell'},
  {name: 'Впечатления о посещении', value: 'impression'},
  {name: 'Заказ', value: 'order'},
  {name: 'Обслуживание', value: 'service'},
  {name: 'Соответствие стандартам', value: 'standard'},
]

const schema = " #:";

const QuestionWindow: React.FC = (props: any) => {
  const { 
    subcategories,
    visible, 
    current, 
    showWindowQuestion, 
    createQuestion,
    updateQuestion,
    clearQuestion
  } = props;
  const [type, setType] = useState<string>('text')
  const [isOne, setIsOne ] = useState<any[]>([]);

  const handleClose = () => {
    showWindowQuestion('views', false)
    clearQuestion()
    setIsOne([])
    setType('text')
  };
  const onSubmit = (data: IQuestion) => {
    // data.balls = +data.balls
    const subcategory = data.subcategory.map( (i: ICategory) => i.id )
    const variants = type === 'isOne' ? isOne.map( (i) => i.value ).join( schema ) : "";
    const commentBy = type === 'isOne' ? isOne.map( (i) => i.comment ).join( schema ) : "";
    const values = {...data, type, subcategory, variants, commentBy, questionOther: false }
    if(type === 'isOne') values.balls = isOne.map( (i) => i.balls ).join( schema )

    current ? updateQuestion(values) : createQuestion(values);
  }

  useEffect( () => {
    if(current) {
      current.balls = current.balls.toString()
      setType(current.type)
      if(current.type === 'isOne') {
        const answers = current.variants.split(schema)
        const comments = current.commentBy.split(schema)
        const balls = current.balls.split(schema)
        const variants = [] as any[]
        answers.forEach( (a: any, index: number) => {
          variants.push({ id: index, value: a, comment: comments[index] === 'true', balls: balls[index] })
        })
  
        setIsOne(variants)

        current.answer = answers
      }
      
    }
  }, [current] )

  let menu = [
    {label: 'Текст', command:()=> setType('text') },
    {label: 'Большой текст', command:()=> setType('area') },
    {label: 'Один из', command:()=> setType('isOne') },
  ]

  return (
    <Dialog header="Редактирование вопроса" visible={visible} style={{ minWidth: '50vw' }} onHide={handleClose}>
      <Form
        onSubmit={onSubmit}
        initialValues={
          current 
          ? {...current, balls: current.balls.toString(), subcategory: subcategories.filter( (i: any) => {
            return current.subcategory.map( (i: any) => i.categoryId ).includes(i.id)
          } ), answer: isOne.map( (a: any) => a.value)} 
          : {}
        }
        styles={{minWidth: '320px'}}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit} className="col-12 p-0">
            <div className="col-12">
              <Field
                validate={composeValidators(requiredValidator)}
                name="subcategory"
                label="Категории заведений:"
                render={MultiSelectField}
                settings={{
                  options: subcategories,
                  optionLabel: "name",
                  filter: true,
                  showClear: true, 
                  filterBy: "name",
                  placeholder: "Выберите категории заведений"
                }}
              />
            </div>

            <div className="col-12">
              <Field
                validate={composeValidators(requiredValidator)}
                name="category"
                label="Категория:"
                render={SelectField}
                settings={{
                  options: categoriesQuestion,
                  optionLabel: "name",
                  filter: true,
                  showClear: true, 
                  filterBy: "name",
                  placeholder: "Выберите категорию"
                }}
              />
            </div>

            <div className="col-12">
              <div className="p-inputgroup">
                
                <Field
                  validate={composeValidators(requiredValidator)}
                  name="question"
                  label="Вопрос:"
                  render={TextAreaField}
                  classNameWrapper="border-radius-tr-0 border-radius-br-0"
                  styleWrapper={{width: "100%"}}
                  settings={{
                    placeholder: "Напишите свой вопрос",
                    rows:1,
                  }}
                />
                <ActionsBtn className="" menuItems={menu} popupId='create-question'/>
              </div>
            </div>

            <QuestionTypesForm type={type} isOne={isOne} setIsOne={setIsOne} />

            {type !== 'isOne' && (
              <div className="col-12">
                <Field
                  validate={composeValidators(requiredValidator)}
                  name="balls"
                  label="Баллы:"
                  render={MaskField}
                  settings={{
                    placeholder: "Укажите кол-во баллов за вопрос",
                    mask: "0"
                  }}
                />
              </div>
            ) }

            <div className="mt-1 col-12 flex flex-wrap justify-content-between">
              <Button className="col-12 md:col-5 xl:col-4 p-button-danger" label="Отмена" onClick={(e) => {
                e.preventDefault();
                handleClose()
              }}/>
              <Button className="col-12 md:col-5 xl:col-4 p-button-success" label="Принять" type="submit"/>
            </div>
          </form>
        )}
      />
      
    </Dialog>
  );
};

const mapStateToProps = (state: any) => ({
  current: state.secretShopper.questions.current,
  subcategories: state.secretShopper.category,
  visible: state.secretShopper.questions.windows.views
})
export default connect(
  mapStateToProps, 
  { 
    createQuestion,
    updateQuestion,
    showWindowQuestion,
    clearQuestion
  } )
(QuestionWindow);