import React from 'react';
import { TextField, TextAreaField, MultiSelectField } from 'components/form/fields';
import { composeValidators, requiredValidator } from 'components/form/validators';

import { Field } from 'react-final-form';
import { connect } from 'react-redux';

interface IProps {
  city?: string | null;
  children?: React.ReactNode;
}

const MainForm: React.FC<IProps> = (props: any) => {
  const {categories, children} = props;
  
  return (
    <div className="card-mini mt-2">

      <div className="flex flex-wrap">
        <div className="col-12 p-0 flex flex-wrap">
          <div className="col-12 md:col-3 align-self-center flex justify-content-center">
            {children}
          </div>
          
          <div className="col-12 md:col-9">
            <div className="pt-2">
              <Field
                validate={composeValidators(requiredValidator)}
                name="name" label="Наименование:" render={TextField}
                settings={{
                  placeholder: "Введите название заведения",
                }}
              />
            </div>

            <div className="pt-2">
              <Field
                validate={composeValidators(requiredValidator)}
                name="categories" label="Категории:" render={MultiSelectField}
                settings={{
                  options: categories,
                  optionLabel: "name", 
                  display: "chip" 
                }}
              />
            </div>

            <div className="pt-2">
              <Field
                validate={composeValidators(requiredValidator)}
                name="addInfo.averageReceipt" 
                label="Средний чек на человека:" 
                render={TextField}
                settings={{
                  placeholder: "Введите возможную сумму чека",
                  keyfilter: "int"
                }}
              />
            </div>
          </div>
        </div>
        
        <div className="col-12">
          <Field
            name="description" label="Описание:" render={TextAreaField}
            settings={{
              placeholder: "Дополнительная информация",
              rows:3,
            }}
          />
        </div>

        <div className="col-12">
          <Field
            name="comment" label="Комментарий:" render={TextAreaField}
            settings={{
              rows:3,
            }}
          />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  categories: state.restaurants.categories
})

export default connect(mapStateToProps)(MainForm);