import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { 
  getQuestionnaireByTaskId, 
  taskComplete,
  taskVerified
} from 'domain/actions/secretShopper.action';

import { Dialog } from 'primereact/dialog';
import ReportForm from './components/report.form';
import { WrapperSpinner } from 'components/static';

interface IProps{
  taskId: string | null;
  handleClose: ()=>void;
}

const ModerationDialog: React.FC<IProps> = (props: any) => {
  const { taskId, visible, progress, handleClose } = props;
  const { getQuestionnaireByTaskId } = props;

  useEffect(() => {
    if(taskId) getQuestionnaireByTaskId(taskId)
  }, [taskId, getQuestionnaireByTaskId])

  return (
    <>
      <Dialog header="Просмотр задания" visible={visible} className="col-12 p-0 md:col-10 xl:col-8" onHide={handleClose}>
        <WrapperSpinner progress={progress}>
          <ReportForm />
        </WrapperSpinner>
      </Dialog>
    </>
  );
};

const mapStateToProps = (state: any) => ({
  visible: state.secretShopper.moderation.windows.views,
  progress: state.secretShopper.tasks.request.loading
})
export default connect(
  mapStateToProps, 
  { 
    getQuestionnaireByTaskId, 
    taskComplete, 
    taskVerified 
  } 
)(ModerationDialog);