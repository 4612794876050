import React, { useEffect } from 'react';
import { FileUpload } from 'primereact/fileupload';
import { isHTTPS } from 'utils/regexp';

interface IProps {
  myUploader?: (event: any) => void;
  onSelectFIle: (event: any) => void;
  onClear?: () => void;
  label: any,
  file: any, 
  currentImg?: string | null,
  alt?: string
}

const SingleUploadImage = React.forwardRef<any, IProps>((props: any, ref: any) => {
  const { label, onSelectFIle, file, currentImg, alt, onClear = () => {} } = props

  const itemTemplate = (file: any, props: any) => {
    const template = (
      <div className="flex align-items-center flex-wrap p-0 justify-content-center text-center">
        {file ? (
          <div className="flex align-items-center flex-wrap p-0">
            <img alt={file.name} role="presentation" src={file.objectURL} style={{width: '100%'}}/>
          </div>
        ) : label}
        
      </div>
    )

    const src=`${isHTTPS(currentImg) ? currentImg : '/api/files/' + currentImg}`
    const currentTemplate = (
      <div className="flex align-items-center flex-wrap p-0 justify-content-center">
        <img alt={alt} role="presentation" src={src} style={{width: 'auto', maxHeight: '250px', maxWidth: "250px"}}/>
      </div>
    )

    return !!currentImg && !file ? currentTemplate : template
  }
  
  useEffect(() => {
    if(ref && ref.current) ref.current.setFiles([file])
  } , [ref, file])

  const chooseOptions = 
    {icon: 'pi pi-fw pi-images', iconOnly: false, className: 'single-choose-btn task-button-success-outline', label: 'Загрузить'};
  const cancelOptions = 
    {icon: 'pi pi-fw pi-times', iconOnly: false, className: 'single-choose-btn p-button-danger p-button-rounded p-button-outlined', label: 'Очистить' };

  const headerTemplate = (options:any) => {
    const { className, chooseButton, cancelButton } = options;

    return (
      <div className={className} style={{backgroundColor: 'transparent', display: 'flex', alignItems: 'center'}}>
        {chooseButton}
        {cancelButton}
      </div>
    );
  }

  const emptyTemplate = () => {
    
    return (
      <div className="flex align-items-center p-dir-col">
        <span className='text-center'>{label}</span>
      </div>
    )
  }

  return (
    <div className="galleria single-uploader">
      <FileUpload ref={ref} name="image" url="" onUpload={()=>{}} accept="image/jpeg,image/png,image/jpg" 
        maxFileSize={20000000} customUpload multiple={false}
        headerTemplate={headerTemplate} 
        chooseOptions={chooseOptions} cancelOptions={cancelOptions} 
        itemTemplate={itemTemplate} 
        onSelect={ (e:any) => {
          if(e.files.length) onSelectFIle(e.files[0]);
        }} 
        emptyTemplate={emptyTemplate} 
        onClear={onClear}
      />
    </div>
  );
});

SingleUploadImage.displayName = 'SingleUploadImage';

export default SingleUploadImage;