import React from 'react';
import { connect } from 'react-redux';
import { showWindow, registration } from 'domain/actions/users.action';

import { Dialog } from 'primereact/dialog';
import { Field, Form } from 'react-final-form';
import { Button } from 'primereact/button';
import { KladrField, MaskField, PasswordField, SelectField, TextAreaField, TextField } from 'components/form/fields';
import { composeValidators, phoneValidator, requiredValidator } from 'components/form/validators';

const RegisterWindows: React.FC = (props: any) => {
  const { visible, showWindow, registration } = props;
  const roleTypes = [{label: 'Владелец', value: 'owner' }, {label: 'Управляющий', value: 'manager'}];

  const handleClose = () => showWindow('register', false);

  const confirmPass = (pass: string) => (value: any) =>
    pass === value ? undefined : `Пароли не совпадают`;

  const onSubmit = (values: any) => registration(values);

  return (
    <Dialog header="Регистрация пользователя" visible={visible} style={{ minWidth: '50vw' }} onHide={handleClose}>
      <Form
        onSubmit={onSubmit}
        initialValues={{}}
        styles={{minWidth: '320px'}}
        render={({ handleSubmit, values }) => (
          <form onSubmit={handleSubmit} className="col-12 p-0">
            <div className="col-12">
              <Field
                validate={composeValidators(requiredValidator, phoneValidator)}
                name="phone"
                label="Телефон(Логин):"
                render={MaskField}
                autocomplete="off"
                settings={{
                  placeholder: "Введите номер телефона",
                  mask: "+00000000000",
                }}
              />
            </div>

            <div className="col-12">
              <Field
                validate={composeValidators(requiredValidator)}
                name="name"
                label="Имя:"
                render={TextField}
                settings={{
                  placeholder: "Имя пользователя"
                }}
              />
            </div>

            <div className="mt-1 mb-2">
              <Field
                validate={composeValidators(requiredValidator)}
                name="city"
                autocomplete="off"
                label="Город проживания"
                render={KladrField}
                settings={{
                  placeholder: "Введите свой город",
                  filterFromBound: "city",
                  filterToBound: "city",
                }}
              />
            </div>

            <div className="mt-1 mb-2">
              <Field
                validate={requiredValidator}
                name="password"
                label="Пароль"
                autocomplete="off"
                render={PasswordField}
                settings={{
                  placeholder: "Введите пароль",
                  feedback: false,
                }}
                icon={["fas", "lock"]}
              />
            </div>

            <div className="mt-1 mb-2">
              <Field
                validate={composeValidators(
                  requiredValidator,
                  confirmPass(values.password)
                )}
                name="confirm"
                label="Повторить пароль"
                render={PasswordField}
                autocomplete="off"
                settings={{
                  placeholder: "Введите пароль повторно",
                  feedback: false,
                }}
                icon={["fas", "check-circle"]}
              />
            </div>


            <div className="mt-1 mb-2">
              <Field
                validate={composeValidators(
                  requiredValidator
                )}
                name="role"
                label="Роль"
                autocomplete="off"
                render={SelectField}
                settings={{
                  options: roleTypes
                }}
              />
            </div>

            <div className="col-12">
              <Field
                name="comment" label="Комментарий:" render={TextAreaField}
              />
            </div>

            <div className="mt-1 col-12 flex flex-wrap justify-content-between">
              <Button className="col-12 md:col-5 xl:col-4 p-button-danger mb-2 md:mb-0" label="Отмена" onClick={ (e) => {
                e.preventDefault();
                handleClose();
              }}/>
              <Button className="col-12 md:col-5 xl:col-4 p-button-success" label="Принять" type="submit"/>
            </div>
          </form>
        )}
      />

    </Dialog>
  );
};

const mapStateToProps = (state: any) => ({
  visible: state.users.windows.register,
})
export default connect(mapStateToProps, { showWindow, registration } )(RegisterWindows);