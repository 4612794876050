import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import {
  createStocks,
} from 'domain/actions/stocks.action';
import { setPageRestaurants, showWindow } from 'domain/actions/restaurants.action';

import { Field, useForm } from "react-final-form";
import { composeValidators, requiredValidator } from "components/form/validators";
import { DateField, SelectField, TextAreaField, TextField } from "components/form/fields";
import { Button } from "primereact/button";
import { getCities } from "domain/actions/cities.action";
import { getRestaurants, setFilters } from "domain/actions/restaurants.action";
import SingleUploadImage from "components/upload/single.upload";
import ListRestaurantWindows from "../list.restaurant.window";


export const AddStock: React.FC<any> = (props: any) => {
  const [ selected, setSelected ] = useState(null);
  const { cities } = props;
  const { handleSubmit, submitting, file, setFile } = props;
  const { handleClose } = props;
  const { setFilters, getRestaurants, getCities, showWindow, setPageRestaurants } = props;
  let refPhoto = useRef<any>(null);

  const form = useForm ();
  const values = form.getState().values;

  useEffect(() => {
    if(values.city) {
      setFilters([values.city], [], ['active'])
      setPageRestaurants(1)
      onSelected(null)
      getRestaurants()
    };
  }, [values.city, getRestaurants, setFilters]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => getCities(), []) // eslint-disable-line react-hooks/exhaustive-deps

  const handleSelect = () => showWindow('list_restaurants', true);
  const onSelected = (value: any) => {
    setSelected(value)
    form.change('restaurant', value )
  }; 

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="mt-2 mb-2">
          <Field
            name="city" label="Город"
            render={SelectField}
            settings={{
              options: cities,
              optionLabel: "name",
              filter: true,
              showClear: true, 
              filterBy: "name",
              placeholder: "Выберите город",
              emptyFilterMessage: "Совпадений не найдено"
            }}
          />
        </div> 

        {values.city && (
          <>

            <div className="mt-2 mb-2">
              <div className="p-inputgroup">
                <div style={{ width: '100%'}}>
                  <Field
                    name="restaurant.name"
                    label="Заведение"
                    render={TextAreaField}
                    settings={{
                      disabled: true,
                    }}
                  />
                </div>
                <Button icon="pi pi-search-plus" className="p-button-success" onClick={(e) => {
                  e.preventDefault();
                  handleSelect();
                }} />
              </div>
            </div>

            <div className="col-12 p-0 flex flex-wrap">
              <div className='mt-2 mb-2'>
                <SingleUploadImage ref={refPhoto} label={"Загрузить фото буклета"} onSelectFIle={setFile} file={file} />
              </div>

              <div className='mt-2 mb-2 col-12 py-0 px-0 md:px-2' style={{maxWidth: '430px'}}>
                <div className='mt-0 mb-1 col-12 py-0 px-0 md:px-2'>
                  <Field
                    validate={composeValidators(requiredValidator)}
                    name="name"
                    label="Название"
                    render={TextField}
                    settings={{
                      placeholder: "Название акции",
                    }}
                  />
                </div>

                <div className='mt-1 mb-1 col-12 py-0 px-0 md:px-2'>
                  <Field
                    validate={composeValidators(requiredValidator)}
                    name="description"
                    label="Описание"
                    render={TextAreaField}
                    settings={{
                      placeholder: "Краткое описание условий акции",
                    }}
                  />
                </div>
              </div>

              <div className='col-12 md:col-6 md:px-2'>
                <Field
                  name="from"
                  label="От"
                  render={DateField}
                />
              </div>

              <div className="col-12 px-0 md:col-6 ">
                <Field
                  name="to"
                  label="До"
                  render={DateField}
                />
              </div>

            </div>

          </>
        )}

        <div className="col-12 p-0 mt-3 flex flex-wrap justify-content-between">
          <Button 
            label="Отмена" 
            className="col-12 md:col-5 mt-1 mb-1 p-button-outlined" 
            onClick={(e)=>{
              e.preventDefault();
              handleClose()
          }} />
          <Button label="Создать" className="col-12 md:col-5 mt-1 mb-1 p-button-raised p-button-success" disabled={submitting} />
        </div>
      </form>

      <ListRestaurantWindows city={values.city ? values.city : null} defaultValue={selected} onSubmit={onSelected} />
    </>
  );
}

const mapStateToProps = (state: any) => ({
  visible: state.stocks.windows.add,
  cities: state.cities.cities,
  restaurants: state.restaurants.restaurants,
})

export default connect(mapStateToProps, { 
  showWindow, createStocks, getCities, setFilters, getRestaurants, setPageRestaurants
})(AddStock);