import { MaskField } from 'components/form/fields';
import { composeValidators, requiredValidator, timeFormatValidator } from 'components/form/validators';
import React, { useEffect, useState } from 'react';
import { Field, useForm  } from 'react-final-form';
import { RadioButton } from 'primereact/radiobutton';
import { Checkbox } from 'primereact/checkbox';

const WorkingForm: React.FC = () => {
  const [ isFullTime, toggleFullTime ] = useState<boolean>(false);
  const form = useForm ();

  const timeTemplates = '00:00 - 23:59';

  useEffect(() =>{
    if(isFullTime) {
      const values = form.getState().values;
      for(let key in values.workingHours) {
        form.change(`workingHours.${key}`, timeTemplates)
      }
    }
  },[isFullTime, form])

  return (
    <div className="card-mini mt-2">

      <div className="col-12 p-field-radiobutton">
        <Checkbox 
          inputId="fullTime" 
          name="fullTime" 
          value="fullTime"
          onChange={(e:any) => toggleFullTime(e.checked)} 
          checked={isFullTime}
          className='mr-2 align-self-center'
        />
        <label htmlFor="fullTime" className='ml-2'>Круглосуточно</label>
      </div>

      <div className="col-12 p-0 flex flex-wrap">
        <div className="col-12 md:col-6">
          <Field
            validate={composeValidators(requiredValidator, timeFormatValidator)}
            name="workingHours.monday"
            label="Понедельник:"
            render={MaskField}
            settings={{
              placeholder: "00:00 - 23:59",
              mask: "#0:*0 - #0:*0",
              definitions: {
                "#": /[0-2]/,
                "*": /[0-5]/
              },
              disabled: isFullTime
            }}
          />
        </div>
        <div className="col-12 md:col-6">
          <Field
            validate={composeValidators(requiredValidator, timeFormatValidator)}
            name="workingHours.tuesday"
            label="Вторник:"
            render={MaskField}
            settings={{
              placeholder: "00:00 - 23:59",
              mask: "#0:*0 - #0:*0",
              definitions: {
                "#": /[0-2]/,
                "*": /[0-5]/
              },
              disabled: isFullTime
            }}
          />
        </div>
        <div className="col-12 md:col-6">
          <Field
            validate={composeValidators(requiredValidator, timeFormatValidator)}
            name="workingHours.wednesday"
            label="Среда:"
            render={MaskField}
            settings={{
              placeholder: "00:00 - 23:59",
              mask: "#0:*0 - #0:*0",
              definitions: {
                "#": /[0-2]/,
                "*": /[0-5]/
              },
              disabled: isFullTime
            }}
          />
        </div>
        <div className="col-12 md:col-6">
          <Field
            validate={composeValidators(requiredValidator, timeFormatValidator)}
            name="workingHours.thursday"
            label="Четверг:"
            render={MaskField}
            settings={{
              placeholder: "00:00 - 23:59",
              mask: "#0:*0 - #0:*0",
              definitions: {
                "#": /[0-2]/,
                "*": /[0-5]/
              },
              disabled: isFullTime
            }}
          />
        </div>
        <div className="col-12 md:col-6">
          <Field
            validate={composeValidators(requiredValidator, timeFormatValidator)}
            name="workingHours.friday"
            label="Пятница:"
            render={MaskField}
            settings={{
              placeholder: "00:00 - 23:59",
              mask: "#0:*0 - #0:*0",
              definitions: {
                "#": /[0-2]/,
                "*": /[0-5]/
              },
              disabled: isFullTime
            }}
          />
        </div>
        <div className="col-12 md:col-6">
          <Field
            validate={composeValidators(requiredValidator, timeFormatValidator)}
            name="workingHours.saturday"
            label="Суббота:"
            render={MaskField}
            settings={{
              placeholder: "00:00 - 23:59",
              mask: "#0:*0 - #0:*0",
              definitions: {
                "#": /[0-2]/,
                "*": /[0-5]/
              },
              disabled: isFullTime
            }}
          />
        </div>
        <div className="col-12 md:col-6">
          <Field
            validate={composeValidators(requiredValidator, timeFormatValidator)}
            name="workingHours.sunday"
            label="Воскресенье:"
            render={MaskField}
            settings={{
              placeholder: "00:00 - 23:59",
              mask: "#0:*0 - #0:*0",
              definitions: {
                "#": /[0-2]/,
                "*": /[0-5]/
              },
              disabled: isFullTime
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default WorkingForm;