import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getOwners, showWindow } from 'domain/actions/users.action';

import { DataTable } from "primereact/datatable";
import { ScrollPanel } from "primereact/scrollpanel";
import { Button } from "primereact/button";
import { Column } from "primereact/column";

import OwnerWindow from './windows/user.window';
import RegisterWindows from "./windows/register.window";
import SettingManagerWindows from "./windows/setting.manager.window";
import ActionsColumn from "pages/views/components/table/action.column";
import { IManagerRestaurants, IRestaurant } from "domain/types/restaurants.type";
import { useMediaQuery } from "react-responsive";
import { toRoleTemplate } from "./components/role";
import { toRestaurantsChips } from "./components/restaurants";
import { toFioTemplate } from "./components/fio";
import { toDate } from "utils/toDate.util";

export const Owners: React.FC = (props: any) => {
  const { loading, owners, getOwners, showWindow } = props;
  const [ currentId, setCurrentId ] = useState<string | null>(null);
  const isMobile = useMediaQuery({
    query: '(max-width: 768px)'
  })

  useEffect(() => getOwners(), [getOwners])

  const handleRegister = () => showWindow('register', true);
  const handleRefresh = () => getOwners();
  const handleViewing = (id: string) => {
    setCurrentId(id)
    showWindow('views', true);
  };
  const handleSettingManager = (id: string) => {
    setCurrentId(id)
    showWindow('setting_manager', true);
  };

  let menu = (item: { accountId: string; role: string }) => {
    const menuItems = [
      {
        label: 'Просмотр', 
        icon: 'pi pi-fw pi-pencil', 
        command:()=> handleViewing(item.accountId)
      }
    ];
    if(item.role === "manager") {
      menuItems.push({
        label: 'Привязать владельца', 
        icon: 'pi pi-fw pi-user-plus', 
        command:()=> handleSettingManager(item.accountId)
      })
    }
    return menuItems;
  }

  const action = (rowData: any) =>
    <ActionsColumn menuItems={menu(rowData)} element={rowData} />

  const toRestaurantsTemplate = (
    data: { 
      restaurants: IRestaurant[], 
      role: string, 
      ManagerRestaurants: IManagerRestaurants[]
    }) => {
    
    const _manger = data.ManagerRestaurants.map( i => i.restaurants) as any[]
    const restMan = [].concat.apply([], _manger);
    const restaurants = 
      data.role === 'manager' 
      ? restMan
      : data.restaurants;

    const chips = toRestaurantsChips(restaurants)

    return <div className="flex flex-wrap">
      {chips}
    </div>
  }

  return (
    <>
      <ScrollPanel style={{ width: '100%', height: 'calc(100vh - 190px)' }}>

        <div className="col-12 p-0 flex flex-wrap align-items-baseline">
        <div className="flex col-12">
          <Button label="Зарегистрировать" onClick={handleRegister} className="p-button-raised p-button-secondary p-button-text mr-2"/>
          <Button label="Обновить" onClick={handleRefresh} className="button-primary-out"/>
        </div>
        </div>

        <div className="card">
        <DataTable 
          value={owners} 
          scrollable={!isMobile} 
          scrollHeight={!isMobile ? "calc(100vh - 310px)" : ""}
          responsiveLayout="stack"
          breakpoint="768px" 
          loading={loading}
          className="table-mobile-rows"
          stripedRows
        >

          <Column field="fio" header="ФИО" body={(rowData)=>toFioTemplate(rowData)} 
            sortable sortField="fio"
          ></Column>

          <Column field="rest" header="Заведения"
            body={(rowData)=>toRestaurantsTemplate(rowData)}
          ></Column>

          <Column field="role" header="Роль" body={(rowData)=>toRoleTemplate(rowData.role)} 
            sortable sortField="role"
            style={{ maxWidth: '7rem' }}
          ></Column>
              
          <Column field="visitAt" header="Последние посещение" 
            sortable sortField="visitAt" style={{ maxWidth: '12rem' }}
            body={(rowData)=>toDate(rowData.visitAt)}
          ></Column>

          <Column body={action} style={{ maxWidth: '5rem' }}></Column>
        </DataTable>
        </div>

      </ScrollPanel>

      <OwnerWindow currentId={currentId} />
      <SettingManagerWindows currentId={currentId} />
      <RegisterWindows />
    </>
  );
}

const mapStateToProps = (state: any) => ({
  owners: state.users.owners,
  loading: state.users.request.loading
})

export default connect(mapStateToProps, { getOwners, showWindow })(Owners);