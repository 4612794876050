import { combineReducers } from 'redux'
import * as constants from "domain/constants/news.constant";
import * as types from "domain/types/news.type";
import { IStatusResponse } from 'domain/types/static.type';

const initialState = {
  data:[],
  current: null,
  windows: { 
    create: false,
    update: false,
    delete: false
  },
  request: {
    loading: false,
    error: null
  }
}


export function data(state = initialState.data, action: types.NewsReduceTypes): any {

  switch (action.type) {
    case constants.NEWS_REDUCE_SET_DATA:
      return action.payload;
    default:
      return state;
  }
}

export function current(state = initialState.current, action: types.NewsReduceTypes): any {
  switch (action.type) {
    case constants.NEWS_REDUCE_SET_CURRENT:
      return action.payload;
    default:
      return state;
  }
}

export function windows(state = initialState.windows, action: types.NewsReduceTypes): any {
  switch (action.type) {
    case constants.NEWS_REDUCE_IS_SHOW:
      const { typeWindow, isShowWindow } = action.payload;
      return {
        ...state,
        [typeWindow]: isShowWindow
      };
    default:
      return state;
  }
}

export function request(state = initialState.request, action: types.NewsReduceTypes): IStatusResponse {
  switch (action.type) {
    case constants.NEWS_REDUCE_CHECKOUT_REQUEST:
      return {
        error: null,
        loading: action.payload.loading,
      };
    default:
      return state;
  }
}

export default combineReducers({
  data,
  current,
  windows,
  request
})