import React, { useEffect } from "react";
import { connect } from "react-redux";
import * as actions from "domain/actions/billing.action";

import ActionsColumn from "pages/views/components/table/action.column";

import { Chip } from 'primereact/chip';
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { ScrollPanel } from "primereact/scrollpanel";
import { useMediaQuery } from "react-responsive";
import { toStatusOrder, toTypeOrder } from "pages/views/controllers/toStatus";

export const Archive: React.FC = (props: any) => {
  const { showWindow, getOrdersArchive, getOrder } = props;
  const { orders, loading } = props;
  const isMobile = useMediaQuery({
    query: '(max-width: 768px)'
  })

  useEffect(() => getOrdersArchive(), []) // eslint-disable-line react-hooks/exhaustive-deps

  const handlePreview = (id: string) => {
    getOrder(id);
    showWindow('view', true);
  }

  let menu = (item: { id: string, order: {state: string}; }) => [
    {
      label: 'Просмотр счета', 
      icon: 'pi pi-fw pi-info-circle', 
      command:()=> handlePreview(item.id)
    }
  ]

  const action = (rowData: any) => <ActionsColumn menuItems={menu(rowData)} element={rowData} />

  const nameTemplate = (restaurant: any, active: boolean) => (
    <span >
      <strong>{restaurant?.name}</strong> - 
      <i>{restaurant?.address}</i> 
      {active 
        ? <Chip label="Активирован" icon="pi pi-check" className="ml-2 chip-confirm" style={{ fontSize: '10px' }}/>
        : <Chip label="Не активирован" icon="pi pi-times" className="ml-2 chip-error" style={{ fontSize: '10px' }}/> 
      }
    </span>
  )

  const toStatusTemplate = (status: string) => <span className={`task-status task-status-${status}`}>{toStatusOrder(status)}</span>
  const toTypePayment = (type: string) => <span className={`task-status task-type-${type}`}>{toTypeOrder(type)}</span>
  
  return (
    <>
      <div className="content">

        <ScrollPanel style={{ width: '100%', height: 'calc(100vh - 214px)' }}>

          <div className="card">
            <DataTable value={orders} 
              scrollable={!isMobile} scrollHeight={!isMobile ? "calc(100vh - 215px)" : ""} 
              responsiveLayout="stack" breakpoint="768px" loading={loading}
              className="table-mobile-rows" stripedRows 
            >
              <Column field="restaurant.name" header="Заведение"
                body={(rowData) => nameTemplate(rowData?.restaurant, rowData?.state === 'confirm')}
                sortable sortField="restaurant.name"
              ></Column>
              
              <Column field="order.order.state" header="Статус" body={(rowData)=>toStatusTemplate(rowData.order.state)} 
                sortable sortField="order.state"
                style={{ maxWidth: '7rem' }}
              ></Column>

              <Column field="order.typePayment" header="Тип оплаты" 
                body={(rowData)=>toTypePayment(rowData.order.typePayment)}
                style={{ maxWidth: '7rem' }}
              ></Column>
              <Column body={action} style={{ maxWidth: '5rem' }}></Column>
            </DataTable>
          </div>

        </ScrollPanel>
      </div>
      
    </>
    
  );
}

const mapStateToProps = (state: any) => ({
  orders: state.billing.orders,
  loading: state.users.request.loading
})

export default connect(mapStateToProps, {...actions})(Archive);