import React from 'react';
import { connect } from 'react-redux';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { showWindow, deleteNews } from 'domain/actions/news.action';
import { INews } from 'domain/types/news.type';

interface IProps {
  item: INews | undefined
}
export const DeleteNewsWindows: React.FC<IProps> = (props: any) => {
  const { visible, showWindow, item, deleteNews } = props;

  const accept = () => deleteNews(item.id)
  const reject = () => showWindow('delete', false)

  return <ConfirmDialog visible={visible} onHide={() => showWindow('delete', false)} 
      message={`Вы действительно хотите безвозвратно удалить элемент "${item?.header}"?`}
      header="Предупреждение" icon="pi pi-exclamation-triangle" accept={accept} reject={reject} 
      className="win-confirm win-confirm--warning"
    />
}

const mapStateToProps = (state: any) => ({
  visible: state.news.windows.delete,
})
export default connect(mapStateToProps, { showWindow, deleteNews } )(DeleteNewsWindows)