import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  createStocks,
  showWindow,
} from 'domain/actions/stocks.action';

import { Dialog } from "primereact/dialog";
import { Form } from "react-final-form";
import AddStock from "./components/add.stock";
import { showError } from "domain/actions/norr.action";

export const AddStockWin: React.FC = (props: any) => {
  const { visible } = props;
  const { showWindow, createStocks, showError } = props;
  const [ file, setFile ] = useState(0);

  useEffect(() => {
    if(!visible) setFile(0)
  }, [visible])

  const handleClose = () => showWindow('add', false);
  const onSubmit = (values: any) => {
    if(!file) return showError('Фото акции не загружена!')
    values.restaurantId = values.restaurant.id
    values.urlPhoto = file
    values.quantity = +values.quantity
    createStocks(values);
  }

  return (
    <Dialog 
      header="Новая акция" 
      visible={visible} 
      className="col-12 p-0 md:col-10 lg:col-8 xl:col-6"
      onHide={handleClose}
      maximizable={true}
    >
      <Form
        onSubmit={onSubmit}
        initialData={null}
        styles={{minWidth: '320px'}}
        render={({ handleSubmit, submitting, values }) => (
          <AddStock 
            handleSubmit={handleSubmit} 
            submitting={submitting} 
            values={values} 
            handleClose={handleClose}
            file={file} 
            setFile={setFile}
          />
        )}
      />
    </Dialog>
  );
}

const mapStateToProps = (state: any) => ({
  visible: state.stocks.windows.add,
  cities: state.cities.cities,
  restaurants: state.restaurants.restaurants,
})

export default connect(mapStateToProps, { 
  showWindow, createStocks, showError
})(AddStockWin);