import React, { useState } from "react";
import { connect } from "react-redux";
import { showWindow } from 'domain/actions/restaurants.action';

import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import AddVideoRutubeDialog from "./add.rutube.window";
import { DataTable } from "primereact/datatable";
import { useMediaQuery } from "react-responsive";
import { Column } from "primereact/column";
import ActionsColumn from "pages/views/components/table/action.column";
import { RutubePreview } from "components/rutube/preview.rutube";
import DeleteVideoRutubeWin from "./delete.video.rutube.window";
import styles from "../styles.module.css";

const imageBody = (rowData: any) => {

  return (
    <div className="flex align-items-center">
      <RutubePreview videoId={rowData.videoId} className={styles["preview_rutube"]}/>
      
      <div className="flex flex-column pl-2">
        <span> <strong>{rowData.title}</strong> </span>
      </div>
    </div>
  );
}

export const ListVideoRutubeDialog: React.FC = (props: any) => {
  const { visible, current, loading } = props;
  const { showWindow } = props;
  const [ videoId, setVideoId ] = useState<string | null>(null);
  const isMobile = useMediaQuery({
    query: '(max-width: 768px)'
  })

  const handleClose = () => showWindow('list_rutube', false);
  const handleAddVideo = () => showWindow('add_video_rutube', true)
  const handleDelete = (id: string) => {
    setVideoId(id)
    showWindow('delete_video_rutube', true)
  };

  let menu = (item: any) => [
    {
      label: 'Удалить видео', 
      icon: 'pi pi-fw pi-trash', 
      command:()=> handleDelete(item.id) 
    }
  ]

  const action = (rowData: any) => <ActionsColumn menuItems={menu(rowData)} element={rowData} />
  
  return (
    <>
      <Dialog header="Список видео с Rutube" visible={visible} style={{minWidth: '383px'}} onHide={handleClose}>
        
        <Button label="Добавить видео" onClick={handleAddVideo}/>

        <DataTable 
          value={current ? current.rutube : []} 
          scrollable={!isMobile} 
          scrollHeight={!isMobile ? "calc(100vh - 324px)" : ""} 
          responsiveLayout="stack"
          loading={loading}
          breakpoint="768px" 
          className="table-mobile-rows not-selection-btn"
        >
          <Column header="Наименование" body={imageBody} ></Column>
          <Column header="Дата создания" field="createdAt" sortable sortField="createdAt"></Column>
          <Column body={action} style={{ maxWidth: '5rem', float: "right" }}></Column>

        </DataTable>

      </Dialog>

      <AddVideoRutubeDialog />
      <DeleteVideoRutubeWin videoId={videoId} />
    </>
  );
}

const mapStateToProps = (state: any) => ({
  current: state.restaurants.current.restaurant,
  visible: state.restaurants.windows.list_rutube,
  loading: state.restaurants.request.loading
})

export default connect(mapStateToProps, { showWindow })(ListVideoRutubeDialog);