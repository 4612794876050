import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getSecretShopperBids, showWindowBid } from 'domain/actions/secretShopper.action';
import BidWindow from './bid.window';
import CreateTaskWindow from './create.task.window';
import CreateBidWindow from './create.bid.window';

import { DataTable } from 'primereact/datatable';
import { ScrollPanel } from 'primereact/scrollpanel';
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { toDate } from "utils/toDate.util";
import { getCities } from "domain/actions/cities.action";
import { getRestaurants } from "domain/actions/restaurants.action";
import { getOwners } from "domain/actions/users.action";

const SecretShopperBids: React.FC = (props: any) => {
  const { 
    bids, 
    getSecretShopperBids,
    getCities, 
    getRestaurants,
    getOwners,
    showWindowBid 
  } = props;
  const [ currentId, setCurrentId ] = useState<string | null>(null);

  useEffect(() => getSecretShopperBids(), [getSecretShopperBids])
  useEffect(() => getCities(), [getCities])
  useEffect(() => getRestaurants(), [getRestaurants])
  useEffect(() => getOwners(), [getOwners])

  const handleCreateBid = () => showWindowBid('createBid', true);
  const handleRefresh = () => getSecretShopperBids();
  const handleViewing = (id: string) => {
    setCurrentId(id)
    showWindowBid('views', true);
  };

  const action = (rowData: {id: string}) => (
    <i className="pi pi-window-maximize mr-2" onClick={()=>handleViewing(rowData.id)}></i>
  )

  return (
    <div className="content">
      <span className="content-header">Заявки</span>

      <ScrollPanel style={{ width: '100%', height: 'calc(100vh - 190px)' }}>

        <div className="col-12 p-0 flex flex-wrap align-items-baseline">
          <div className="flex col-12 md:col-4 xl:col-3">
            <Button label="Создать" onClick={handleCreateBid} className="p-button-success mr-2"/>
            <Button label="Обновить" onClick={handleRefresh} className="button-primary-out"/>
          </div>
        </div>

        <div className="card">
          <DataTable value={bids} scrollable scrollHeight="calc(100vh - 310px)" >
            <Column field="restaurant" header="Заведение"></Column>
            <Column field="cost" header="Цена"></Column>
            <Column field="status" header="Статус"></Column>
            <Column field="createdAt" header="Дата создания" body={(rowData)=>toDate(rowData.createdAt)}></Column>
            <Column body={action} style={{ maxWidth: '5rem' }}></Column>
          </DataTable>
        </div>

      </ScrollPanel>

      <BidWindow currentId={currentId} />
      <CreateTaskWindow currentId={currentId}/>
      <CreateBidWindow />
    </div>
  );
}

const mapStateToProps = (state: any) => ({
  bids: state.secretShopper.bids.data
})

export default connect(mapStateToProps, 
  { 
    getSecretShopperBids, 
    getCities, 
    getRestaurants,
    getOwners,
    showWindowBid 
  } )(SecretShopperBids);