import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { 
  showWindowSchema, 
  createSchema, 
  updateSchema,
  clearSchema
} from 'domain/actions/secretShopper.action';

import { Dialog } from 'primereact/dialog';
import { IQuestion, ISchema } from 'domain/types/secretShopper.type';
import { Button } from 'primereact/button';
import { Field, Form } from 'react-final-form';
import { SelectField, TextField } from 'components/form/fields';

import GroupQuestions from './group.questions';
import { TabPanel, TabView } from 'primereact/tabview';
import { composeValidators, requiredValidator } from 'components/form/validators';
import { ICategory } from 'domain/types/restaurants.type';

const SchemaWindow: React.FC = (props: any) => {
  const [data, setData] = useState<any>(null);
  const [ selectedQuestions, setSelectedQuestions ] = useState<any[]>([]);
  const [ selectedSelf, setSelectedSelf ] = useState<any[]>([]);
  const { 
    visible, 
    current,
    questions, 
    showWindowSchema, 
    createSchema, 
    updateSchema,
    clearSchema,
    subcategories,
    loading
  } = props;

  const setSubcategory = (category: ICategory) => {
    const filter = subcategories
      .filter( (cat: any) => cat.id === category.id)
    
      return filter.length ? filter[0].id : null
  }

  useEffect(() =>{
    if(!current){
      setSelectedQuestions([]);
      setSelectedSelf([]);
      setData(null);
      return ;
    }
    
    if(current) setSelectedQuestions(current.questions.filter( (i: any) => i.option === 'waiter').map( (q: any) => ({...q.question, priority: q.priority})));
    if(current) setSelectedSelf(current.questions.filter( (i: any) => i.option === 'self').map( (q: any) => ({...q.question, priority: q.priority}) ));
    
    setData({
      ...current,
      category: subcategories.filter( (cat: any) => cat.id === current.categoryId)?.[0]
    })

  }, [current]) // eslint-disable-line react-hooks/exhaustive-deps
  
  const handleClose = () => {
    showWindowSchema('views', false)
    clearSchema()
  };
  const onSubmit = (data: ISchema) => {
    
    const waiter = selectedQuestions.map( (q: IQuestion) => ({option: 'waiter', questionId: q.id, priority: q.priority}) )
    const self = selectedSelf.map( (q: IQuestion) => ({option: 'self', questionId: q.id, priority: q.priority}) )
    data.questions = [...waiter, ...self]
    data.categoryId = data.category.id
    
    current ? updateSchema(data) : createSchema(data);
  }

  return (
    <Dialog header="Редактирование шаблона" visible={visible} style={{ minWidth: '320px' }} onHide={handleClose}>
      <Form
        onSubmit={onSubmit}
        initialValues={data}
        styles={{minWidth: '320px'}}
        render={({ handleSubmit, values }) => (
          <form onSubmit={handleSubmit} className="col-12 p-0">

            <div className="col-12">
              <Field
                name="category"
                label="Тип анкеты:"
                render={SelectField}
                validate={composeValidators(requiredValidator)}
                settings={{
                  options: subcategories,
                  optionLabel: "name",
                  filter: true,
                  showClear: true, 
                  filterBy: "name",
                  placeholder: "Выберите тип анкеты",
                  disabled: !!current || selectedQuestions.length || selectedSelf.length
                }}
              />
            </div>

            <div className="col-12">
              <Field
                name="name"
                label="Наименование:"
                render={TextField}
                validate={composeValidators(requiredValidator)}
                settings={{
                  placeholder: "Напишите название анкеты"
                }}
              />
            </div>
            {!!values?.category && (
              <TabView>
                <TabPanel header="Официант">
                  <h4 className="m-2">Список вопросов</h4>
                  <GroupQuestions questions={questions} selected={selectedQuestions} setSelected={setSelectedQuestions} filter={setSubcategory(values.category)}/>
                </TabPanel>
                <TabPanel header="Самообслуживание">
                  <h4 className="m-2">Список вопросов</h4>
                  <GroupQuestions questions={questions} selected={selectedSelf} setSelected={setSelectedSelf} filter={setSubcategory(values.category)}/>
                </TabPanel>
              </TabView>
            )}
            

            <div className="mt-1 col-12 flex flex-wrap justify-content-between">
              <Button className="col-12 md:col-5 xl:col-4 p-button-danger mb-2" label="Отмена" onClick={(e:any) => {
                e.preventDefault()

                handleClose()
              }}/>
              <Button className="col-12 md:col-5 xl:col-4 p-button-success" label="Принять" type="submit" disabled={!values?.category || loading}/>
            </div>
          </form>
        )}
      />
      
    </Dialog>
  );
};

const mapStateToProps = (state: any) => ({
  questions: state.secretShopper.questions.data,
  current: state.secretShopper.schemas.current,
  subcategories: state.secretShopper.category,
  visible: state.secretShopper.schemas.windows.views,
  loading: state.secretShopper.questions.request.loading
})
export default connect(
  mapStateToProps, 
  { 
    showWindowSchema, 
    createSchema, 
    updateSchema,
    clearSchema
  } )
(SchemaWindow);