import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { 
  setPaymentReceipt, 
  uploadReceipt,
  setUrlReceipt,
  getRequestRemuneration
} from 'domain/actions/secretShopper.action';

import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { WrapperSpinner } from 'components/static';

interface IProps{
  currentId: string | null;
  handleClose: () => void;
}

const ReceiptDialog: React.FC<IProps> = (props: any) => {
  const { currentId, visible, upload, uploadReceipt, setUrlReceipt, request, progress, handleClose } = props;
  const { setPaymentReceipt, getRequestRemuneration } = props;
  const inputFile = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if(!currentId) return ;
    getRequestRemuneration(currentId)
  }, [currentId, getRequestRemuneration]);

  useEffect(() => {
    if(!visible) setUrlReceipt(null);
  }, [setUrlReceipt, visible])

  const handlerLoader = () => {
    if(inputFile && inputFile.current) inputFile.current.click()
  }
  const fileSelectHandler = (event: any) => {
    uploadReceipt(currentId, event.target.files[0])
  };

  const onSubmit = () => {
    if(!upload) return alert("Чек не загружен!");
    setPaymentReceipt(currentId)
    handleClose()
  }

  return (
      <Dialog header="Чек оплаты" visible={visible} style={{minWidth: '383px'}} onHide={handleClose}>
        <WrapperSpinner progress={progress} >
        <div className="mt-3 mb-2 col-12">

          <div className="col-12">
            <div className="block-label-title">Номер телефона:</div>
            <div className="block-label-value" >{request?.phone ? request.phone : '-'}</div>
          </div>

          <div className="col-12">
            <div className="block-label-title">Банк:</div>
            <div className="block-label-value" >{request?.bankAlias ? request.bankAlias : '-'}</div>
          </div>

          <div className="col-12">
            <div className="block-label-title">ФИО держателя карты:</div>
            <div className="block-label-value" >{request?.fio}</div>
          </div>

          <div className="m-1">
            <img src={`/api/admin/files/receipts/${request?.receipt && !upload ? request?.receipt : upload}`} alt="" width="325px"
              onError={(e: any) => e.target.src='/api/admin/files/default'} />
          </div>
          <div className="m-1">
            <input type="file" style={{display: 'none'}} onChange={fileSelectHandler} ref={inputFile}/>
            <Button className="col-12 mt-1 mb-1" label={upload ? "Чек загружен" : "Загрузить чек"} onClick={handlerLoader}/>
            <Button className="col-12 p-button-success mt-3" label="Подтвердить" onClick={onSubmit}/>
          </div>
        </div>
        </WrapperSpinner>
      </Dialog>
  );
};

const mapStateToProps = (state: any) => ({
  upload: state.secretShopper.tasks.receipt,
  visible: state.secretShopper.tasks.windows.payment,
  request: state.secretShopper.tasks.requestsRemuneration,
  progress: state.secretShopper.tasks.request.loading
})
export default connect(mapStateToProps, 
  {  setPaymentReceipt, uploadReceipt, setUrlReceipt, getRequestRemuneration } 
)(ReceiptDialog);