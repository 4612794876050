import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getCategories, showWindow, deleteCategoryDishes } from 'domain/actions/restaurants.action';

import { ICategory } from "domain/types/restaurants.type";
import { DataTable } from "primereact/datatable";
import { ScrollPanel } from "primereact/scrollpanel";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import ActionsColumn from "pages/views/components/table/action.column";
import EditCategoryWin from "./edit.category.window";
import { DeleteWin } from "pages/views/components";
import { InputText } from "primereact/inputtext";



export const CategoryDishes: React.FC = (props: any) => {
  const { loading, categories, getCategories, showWindow, deleteCategoryDishes } = props;
  const [selected, setSelected] = useState<ICategory | null>(null);
  const [ show, setShow ] = useState<boolean>(false)
  const [globalFilter, setGlobalFilter] = useState(null);

  useEffect(() => getCategories(), []) // eslint-disable-line react-hooks/exhaustive-deps

  const handleAdd = () => showWindow('addCategory', true);
  const handleRefresh = () => getCategories();
  const handleEdit = (item: ICategory) => {
    showWindow('editCategory', true)
    setSelected(item)
  };
  const handleDelete = (_item: ICategory) => {
    setSelected( _item )
    setShow(true)
  };
  const onDelete = () => {
    if(selected) deleteCategoryDishes(selected.id, () => {
      setSelected(null)
      setShow(false)
    })
  };

  let menu = (item: ICategory) => [
    {
      label: 'Редактировать', 
      icon: 'pi pi-fw pi-check-circle', 
      command:()=> handleEdit(item)
    },
    {label: 'Удалить', icon: 'pi pi-fw pi-trash', command:()=> handleDelete(item) }
  ]

  const action = (rowData: any) => <ActionsColumn menuItems={menu(rowData)} element={rowData} />

  const renderHeader = () => {
    return (
      <div className="table-header">
        <div className="col-12 pl-0 flex flex-wrap align-items-baseline justify-content-between">
          <div className="flex flex-wrap p-1">
            <Button label="Добавить" onClick={handleAdd} className="p-button-raised p-button-success mr-1"/>
            <Button label="Обновить" onClick={handleRefresh} className="p-button-raised p-button-info p-button-text ml-1"/>
          </div>

          <span className="p-input-icon-left p-1">
            <i className="pi pi-search pl-1" />
            <InputText type="search" onInput={(e: any) => setGlobalFilter(e.target.value)} placeholder="Глобальный поиск" />
          </span>
        </div>
        
      </div>
    );
  }
  const header = renderHeader();

  return (
    <>

      <ScrollPanel style={{ width: '100%', height: 'calc(100vh - 205px)' }}>

        <div className="card">
        <DataTable header={header} globalFilter={globalFilter} value={categories} scrollable scrollHeight="calc(100vh - 310px)" responsiveLayout="scroll" loading={loading}>
          <Column field="name" header="Название"></Column>
          <Column field="description" header="Описание"></Column>
          <Column body={action} style={{ maxWidth: '5rem' }}></Column>
        </DataTable>
        </div>

      </ScrollPanel>

      <EditCategoryWin selected={selected} />
      <DeleteWin show={show} showWindow={setShow} item={selected} deleteItem={onDelete} />
    </>
  );
}

const mapStateToProps = (state: any) => ({
  categories: state.restaurants.kitchen.categories,
  loading: state.restaurants.request.loading
})

export default connect(mapStateToProps, { getCategories, showWindow, deleteCategoryDishes })(CategoryDishes);