import { call, fork, put, takeEvery } from 'redux-saga/effects'
import * as types from 'domain/types/news.type';
import * as actions from "domain/actions/news.action";
import * as constants from "domain/constants/news.constant";
// import * as reducers from "domain/reducers/news.reduce";
import { Result } from 'domain/types/static.type';
import { NorrController } from 'domain/controllers/Response.controller';
import ApiController from 'domain/controllers/Api.controller';

// const api = new BillingController();
const api = new ApiController();
const norr = new NorrController();

export async function getNewsFetch(): Promise<Result<any>>{
  return await api.get(`/news`);
}

export async function getNewsByIdFetch(id: string): Promise<Result<any>>{
  return await api.get(`/news/${id}`);
}

export async function createNewsFetch(params: types.INews) {
  return await api.post(`/news`, { data: params });
}

export async function updateNewsFetch(params: types.INews) {
  return await api.put(`/news/${params.id}`, { data: params });
}

export async function deleteNewsFetch(id: string) {
  return await api.delete(`/news/${id}`);
}


export async function uploadNewsPhotoFetch(file: any): Promise<Result<string>> {
  const fd = new FormData();
  fd.append('image', file, file.name)
  return await api.post(`/news/preview`, fd)
}

function* getNews(): any{
  yield put(actions.reqNews(true))

  const response = yield call(getNewsFetch)

  yield call(norr.processing, response, function *(){
    yield put(actions.setNews(response.value))
  })

  yield put(actions.reqNews(false))
}

function* getNewsById(action:any): any{
  yield put(actions.reqNews(true))

  const id = action.payload.id;

  const response = yield call(getNewsByIdFetch, id)

  yield call(norr.processing, response, function *(){
    yield put(actions.setCurrentNews(response.value))
    yield put(actions.showWindow('update', true))
  })

  yield put(actions.reqNews(false))
}

function* createNews(action:any): any{

  const values = action.payload;

  if(values.file){
    const photo = yield call(uploadNewsPhotoFetch, values.file);
    
    yield call(norr.processing, photo, function *(){
      values.data.preview = photo.value;
    })
    
    if(!photo.value) return;
  }

  const response = yield call(createNewsFetch, values.data)

  yield call(norr.processing, response, function *(){
    yield call(getNews)
    yield put(actions.showWindow('create', false))
  })

}

function* updateNews(action:any): any{
  const values = action.payload;

  if(values.file){
    const photo = yield call(uploadNewsPhotoFetch, values.file);
    yield call(norr.processing, photo, function *(){
      values.values.preview = photo.value;
    })
    
    if(!photo.value) return;
  }

  const response = yield call(updateNewsFetch, values.values)

  yield call(norr.processing, response, function *(){
    yield call(getNews)
    yield put(actions.showWindow('update', false))
  }, "Изменение прошло успешно")

}

function* updateActiveNews(action:any): any{
  let values = action.payload.data;
  values.isActive = !values.isActive;

  const response = yield call(updateNewsFetch, values)

  yield call(norr.processing, response, function *(){
    yield call(getNews)
  })

}

function* deleteNews(action:any): any{

  const newsId = action.payload.newsId;

  const response = yield call(deleteNewsFetch, newsId)

  yield call(norr.processing, response, function *(){
    yield call(getNews)
    yield put(actions.showWindow('delete', false))
  })

}

export function* watch() {
  yield takeEvery(constants.NEWS_SAGA_GET_DATA, getNews)
  yield takeEvery(constants.NEWS_SAGA_GET_BY_ID, getNewsById)
  yield takeEvery(constants.NEWS_SAGA_CREATE, createNews)
  yield takeEvery(constants.NEWS_SAGA_UPDATE, updateNews)
  yield takeEvery(constants.NEWS_SAGA_UPDATE_ACTIVE, updateActiveNews)
  yield takeEvery(constants.NEWS_SAGA_DELETE, deleteNews)
}

export default function* sagas() {
  yield fork(watch)
}