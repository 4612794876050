import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { createBid, showWindowBid  } from 'domain/actions/secretShopper.action';
import { Field, withTypes } from "react-final-form";
import { TextAreaField, SelectField, TextField } from 'components/form/fields';
import { composeValidators, requiredValidator } from 'components/form/validators';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { IRestaurant } from 'domain/types/restaurants.type';
import { IUser } from 'domain/types/users.type';
import { ICity } from 'domain/types/city.type';

//form
export interface IForm {
  city: ICity | null;
  restaurant: IRestaurant | null;
  owner: IUser | null;
  comment: string;
  cost: string
}

const { Form } = withTypes<IForm>()

const CreateBidDialog: React.FC = (props: any) => {
  const { visible, showWindowBid, createBid, restaurants, owners, cities } = props;
  const [citiesC, setCitiesC ] = useState(cities)
  const [city, setCity] = useState<any>(null);
  const [restaurantsC, setRestaurantsC ] = useState(restaurants)
  const [restaurant, setRestaurant] = useState<any>(null);
  const [ownersC, setOwnersC ] = useState(owners)
  const [owner, setOwner] = useState<any>(null);

  useEffect(()=> setCitiesC(cities), [cities, setCitiesC])
  useEffect(()=> setRestaurantsC(restaurants), [restaurants, setRestaurantsC])
  useEffect(()=> setOwnersC([]), [owners, setOwnersC])
  useEffect(()=> {
    if(!visible) {
      setCity(null);
      setRestaurant(null);
      setOwner(null);
    }
  }, [visible])

  const [ data, setData] = useState<IForm>({
    city: null,
    restaurant: null,
    owner: null,
    comment: '',
    cost: ''
  });

  useEffect(() =>{
    setData({
      city: city,
      restaurant: restaurant,
      owner: owner,
      comment: '',
      cost: ''
    })
  }, [city, restaurant, owner])

  useEffect(() =>{
    if(city) setRestaurantsC( restaurants.filter( (item: any) => item.cityId === city.id ))
    if(city) setRestaurant(null)
    if(city) setOwner(null)
  }, [city, restaurants])

  useEffect(() =>{
    if(restaurant) {
      const resultOwners = owners.filter( (item: any) => item.accountId === restaurant.ownerId )
      setOwnersC(resultOwners)
      setOwner(resultOwners.length ? resultOwners[0] : null)
    }
  }, [restaurant, owners, cities])

  const ownersMap = ownersC.map( (owner: any) => {
    const surname = owner.surname ? owner.surname : '';
    owner['fullName'] = `${surname} ${owner.name}`;
    return owner;
  } )

  const handleClose = () => showWindowBid('createBid', false);
  const onSubmit = (values: IForm) => createBid(values);

  return (
    <Dialog header="Создание заявки" visible={visible} style={{ maxWidth: '436px', minWidth: '375px' }} onHide={handleClose}>
      <Form
        onSubmit={onSubmit}
        initialValues={data}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <div className="mt-2 mb-2">
              <Field
                name="city" label="Город:"
                render={SelectField}
                settings={{
                  onChange: (e:any) => setCity(e.value),
                  options: citiesC,
                  optionLabel: "name",
                  filter: true,
                  showClear: true, 
                  filterBy: "name",
                  placeholder: "Выберите город"
                }}
              />
            </div>
            <div className="mt-2 mb-2">
              <Field
                validate={composeValidators(requiredValidator)}
                name="restaurant" label="Заведение:"
                render={SelectField}
                settings={{
                  onChange: (e:any) => setRestaurant(e.value),
                  options: restaurantsC,
                  optionLabel: "name",
                  filter: true,
                  showClear: true, 
                  filterBy: "name",
                  placeholder: "Выберите заведение"
                }}
              />
            </div>
            <div className="mt-2 mb-2">
              <Field
                validate={composeValidators(requiredValidator)}
                name="owner" label="Владелец:"
                render={SelectField}
                settings={{
                  onChange: (e:any) => setOwner(e.value),
                  options: ownersMap,
                  optionLabel: "fullName",
                  filter: true,
                  showClear: true, 
                  filterBy: "fullName",
                  placeholder: "Выберите владельца"
                }}
              />
            </div>

            <div className="mt-2 mb-2">
              <Field
                validate={composeValidators(requiredValidator)}
                name="cost"
                label="Цена:"
                render={TextField}
                settings={{
                  placeholder: "Введите цену",
                  keyfilter: "int"
                }}
              />
            </div>

            <div className="mt-2 mb-2">
              <Field
                validate={composeValidators(requiredValidator)}
                name="comment"
                label="Комментарий:"
                render={TextAreaField}
                settings={{
                  rows:5,
                  cols:35
                }}
              />
            </div>
            
            <div className="mt-1 flex justify-content-end">
              <Button 
                label="Создать" 
                type="submit"
              />
            </div>
          </form>
        )}
      /> 
    </Dialog>
  );
};

const mapStateToProps = (state: any) => ({
  cities: state.cities.cities,
  restaurants: state.restaurants.restaurants,
  owners: state.users.owners,
  visible: state.secretShopper.bids.windows.createBid
})
export default connect(mapStateToProps, { createBid, showWindowBid } )(CreateBidDialog);