import React from "react";
import { PrivateRoute } from "pages/routes/config/TypeRoute";

import { Outlet } from "react-router-dom";

export const News: React.FC = (props: any) => {
  
  return (
    <PrivateRoute>
      <>
        <h1 style={{margin: '0px'}}>Новости</h1>
        <Outlet />
      </>
    </PrivateRoute>
  );
}

export default News;