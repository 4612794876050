import { combineReducers } from 'redux';

import auth from './auth.reduce';
import billing from './billing.reduce';
import secretShopper from './secretShopper.reduce';
import users from './users.reduce';
import restaurants from './restaurants.reduce';
import cities from './cities.reduce';
import norr from './norr.reduce';
import hashtags from './hashtags.reduce';
import notification from './notification.reduce';
import stocks from './stocks.reduce';
import coupons from './coupons.reduce';
import settings from './settings.reduce';
import news from './news.reduce';

const rootReducer = combineReducers({
  auth,
  billing,
  secretShopper,
  users,
  restaurants,
  stocks,
  coupons,
  cities,
  norr,
  news,
  hashtags,
  notification,
  settings
});

export default rootReducer;
export type RootState = ReturnType<typeof rootReducer>;