import React, { useRef, useState } from 'react';
import { connect } from 'react-redux';
import { ScrollPanel } from 'primereact/scrollpanel';
import { Button } from 'primereact/button';
import { Galleria } from 'primereact/galleria';
import { IGallery } from 'domain/types/restaurants.type';
import { showWindow } from 'domain/actions/restaurants.action';
import DeleteImageWin from "./delete.images.window";

interface IProps {
  images: Array<IGallery>
}

export const GalleriaImage: React.FC<IProps> = (props:any, ref:any) => {
  const { images, showWindow } = props;
  const galleria = useRef<any>(null);
  const [ reload, setReload ] = useState(false);
  const [ image, setImage ] = useState<IGallery | null>(null);
  const [ activeIndex, setActiveIndex ] = useState(0);
  const [ responsiveOptions ] = useState([
    {breakpoint: '1024px', numVisible: 5 },
    {breakpoint: '960px',  numVisible: 4 },
    {breakpoint: '768px',  numVisible: 3 },
    {breakpoint: '560px',  numVisible: 1 }
  ]);

  const handleDelete = (e: any, image: IGallery) => {
    e.preventDefault();
    setImage(image)
    showWindow('deleteImage', true)
  }
  const deleteImageGallery = (image: IGallery) => {
    const index = images.indexOf(image)
    if(index !== -1) images.splice(index, 1)
    setReload( true )
    setTimeout(() => setReload( false ), 1000)
  }

  const thumbnailTemplate = (item: any) => (
    <div className="grid grid-nogutter justify-content-center">
      <img src={`/api/files/${item.link}`} width="50" alt={item?.alt} style={{ display: 'block' }} />
    </div>
  )

  const renderImages = images.map((image: any, index: number) => {
    let imgEl = <img src={`/api/files/${image.link}`} alt={image.alt} style={{ cursor: 'pointer', width: "100%" }} onClick={() => {
      setActiveIndex(index);
      if(galleria && galleria.current) galleria.current.show()
    }} />
    return (
      <div className="col-12 md:col-6 lg:col-4 xl:col-3 gallery-images-list" key={index}>
        {imgEl}
        <Button icon="pi pi-trash" className="p-button-rounded p-button-danger p-button-outlined" onClick={(e)=>handleDelete(e, image)}/>
      </div>
    )
  })

  const itemTemplate = (item: any) => <img src={`/api/files/${item.link}`} alt={item?.alt} style={{ width: '100%', display: 'block' }} />

  return (
    <div className="galleria">
      <div className="card" key={Number(reload)}>

          <Galleria ref={galleria} value={images} responsiveOptions={responsiveOptions} numVisible={7} style={{ maxWidth: '850px' }}
            activeIndex={activeIndex} onItemChange={(e) => setActiveIndex(e.index)}
            circular fullScreen showItemNavigators showThumbnails={false} item={itemTemplate} thumbnail={thumbnailTemplate}/>
              
          <ScrollPanel style={{ width: '100%', height: '250px' }} className="gallery-scroll" >
            <div className="grid flex flex-wrap">
              { renderImages }
            </div>
          </ScrollPanel>
      </div>

      <DeleteImageWin image={image} deleteImageGallery={deleteImageGallery}/>
    </div>
  );
}

export default connect(null, {showWindow})(GalleriaImage);