import * as constants from "domain/constants/news.constant";
import * as types from "domain/types/news.type";

export function getNews(){
  return {
    type: constants.NEWS_SAGA_GET_DATA
  }
}

export function getNewsById(id: string){
  return {
    type: constants.NEWS_SAGA_GET_BY_ID,
    payload: { id }
  }
}

export function createNews(data: types.INews, file: any){
  return {
    type: constants.NEWS_SAGA_CREATE,
    payload: { data, file }
  }
}

export function updateNews(id: string, data: types.INews, file: any){
  return {
    type: constants.NEWS_SAGA_UPDATE,
    payload: { newsId: id, values: data, file }
  }
}

export function updateActiveNews(id: string, data: types.INews){
  return {
    type: constants.NEWS_SAGA_UPDATE_ACTIVE,
    payload: { newsId: id, data }
  }
}

export function deleteNews(id: string){
  return {
    type: constants.NEWS_SAGA_DELETE,
    payload: { newsId: id }
  }
}

export function setNews(news: types.INews[]){
  return {
    type: constants.NEWS_REDUCE_SET_DATA,
    payload: news
  }
}

export function setCurrentNews(news: types.INews){
  return {
    type: constants.NEWS_REDUCE_SET_CURRENT,
    payload: news
  }
}

export function showWindow(window: string, isShow: boolean ): types.NewsReduceTypes{
  return {
    type: constants.NEWS_REDUCE_IS_SHOW,
    payload: { 
      typeWindow: window,
      isShowWindow: isShow
    }
  }
}

export function reqNews(isLoad: boolean): types.NewsReduceTypes{
  return {
    type: constants.NEWS_REDUCE_CHECKOUT_REQUEST,
    payload: { loading: isLoad }
  }
}