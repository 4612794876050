import React, { useEffect, useState } from 'react';

import { IQuestion } from 'domain/types/secretShopper.type';
import SwitchField from './switch.field';

interface IProps {
  disabled?: boolean;
  questions: IQuestion[];
}

export const OrderContent: React.FC<IProps> = (props: any) => {
  const { disabled = false, questions } = props;
  const [ filters, setFilter ] = useState<IQuestion[]>([]);

  useEffect(() => {
    const filtered = questions.filter((question: IQuestion) => question.category === 'order')
    
    setFilter(filtered);
  }, [questions])

  const classField = "col-12 md:col-6 xl:col-4";

  const rerender = filters.map((question: IQuestion) =>{
    return (
      <div className={classField} key={question.id}>
        <SwitchField question={question} disabled={disabled} />
      </div>
    )
  })

  return (
    <>
      {!!filters.length && (
        <>
          <h3 className="m-2">Заказ: </h3>
    
          <div className="flex flex-wrap col-12 p-0">
            {rerender}
          </div>
        </>
      )}
    </>
  );
};

export default OrderContent;