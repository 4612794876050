import React, { useEffect, useState } from 'react';
import { connect } from "react-redux";

import { Field, useForm } from 'react-final-form';
import { composeValidators, requiredValidator } from 'components/form/validators';
import { TextAreaField } from 'components/form/fields';
import { showWindow } from 'domain/actions/users.action';

import { Button } from 'primereact/button';
import { showWindowSchema } from 'domain/actions/secretShopper.action';
import { getClients } from 'domain/actions/users.action';
import ListUserWindows from '../../users/windows/list.users.window';

export const CreateCouponForm: React.FC<any> = (props: any) => {
  const [ selected, setSelected ] = useState(null);
  const { getClients, showWindow } = props;
  const { submitting, handleSubmit, setType } = props;
  const form = useForm ();

  const {
    showWindowSchema,
    selectedQuestionnaire
  } = props;

  useEffect(() => {
    getClients()
  }, [getClients])

  const handleQuestionnaire = (e: any) => {
    e.preventDefault();

    showWindowSchema("schemaQuestionnaire", true);
  }
  const handleSelect = () => showWindow('list_users', true);
  const onSelectedOwner = (value: any) => {
    if(value) {
      value.label = `${value.name} ${value.surname ? value.surname : ''} (tel:${value.phone ?? '-' })`
    
      setSelected(value)
      form.change('account', value )
    }
  }; 

  return (
    <>
      <form onSubmit={handleSubmit}>
    
        <div className="mt-2 mb-2">
          <div className="p-inputgroup">
            <div style={{ width: '100%'}}>
              <Field
                validate={composeValidators(requiredValidator)}
                name="account.label"
                label="Пользователь"
                render={TextAreaField}
                settings={{
                  disabled: true,
                }}
              />
            </div>
            <Button icon="pi pi-search-plus" className="p-button-success" onClick={(e) => {
              e.preventDefault();
              handleSelect();
            }} />
          </div>
        </div>  

      <div className='col-12 p-0'>
        <Button 
          label={ selectedQuestionnaire ? `Выбрано: ${selectedQuestionnaire.name}` : `Выбрать анкету`} 
          className="p-button-outlined p-button-secondary col-12" 
          onClick={handleQuestionnaire}
        />
      </div>
        

        <div className="col-12 p-0 mt-3 flex flex-wrap justify-content-between">
          <Button label="Назад" className="col-12 md:col-5 mt-1 mb-1 p-button-outlined" onClick={(e)=>{
            e.preventDefault();
            setType(null)
          }} />
          <Button label="Сформировать" className="col-12 md:col-5 mt-1 mb-1 p-button-raised p-button-success" disabled={submitting} />
        </div>
      </form>

      <ListUserWindows type={'clients'} onSubmit={onSelectedOwner} defaultValue={selected} />
    </>
  );
};

const mapStateToProps = (state: any) => ({
  clients: state.users.clients,
  selectedQuestionnaire: state.secretShopper.schemas.current 
})

export default connect(mapStateToProps, {
  getClients, 
  showWindowSchema,
  showWindow
})(CreateCouponForm);