import React, { useEffect } from "react";
import { connect } from "react-redux";
import {
  showWindow,
} from 'domain/actions/restaurants.action';

import { Dialog } from "primereact/dialog";
import FiltersContent from "./filters";
import { getCities } from "domain/actions/cities.action";

export const FiltersWin: React.FC = (props: any) => {
  const { visible, showWindow, getCities } = props;

  useEffect(() => getCities(), []) // eslint-disable-line react-hooks/exhaustive-deps

  const handleClose = () => showWindow('filters', false);

  return (
    <Dialog 
      header="Фильтры по заведениям" 
      visible={visible} 
      className="col-12 p-0 md:col-10 lg:col-8"
      onHide={handleClose}
      maximizable={true}
    >
      <FiltersContent />
    </Dialog>
  );
}

const mapStateToProps = (state: any) => ({
  visible: state.restaurants.windows.filters
})

export default connect(mapStateToProps, { 
  showWindow, getCities
})(FiltersWin);