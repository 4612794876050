import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { showWindow, bindOwner } from 'domain/actions/restaurants.action';

import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Field, Form } from "react-final-form";
import { composeValidators, requiredValidator } from "components/form/validators";
import { TextAreaField } from "components/form/fields";
import { IUser } from "domain/types/users.type";
import { showWindow as showWinUsers } from 'domain/actions/users.action';
import ListUserWindows from "../../users/windows/list.users.window";


export const BindOwnerDialog: React.FC = (props: any) => {
  const [ owner, setOwner ] = useState<IUser | null>(null);
  const [ select, setSelect ] = useState<any>({ owner: null});
  const { visible, current, owners } = props;
  const { showWindow, bindOwner, showWinUsers } = props;

  useEffect(() =>{
    if(current) {
      const result = owners.filter( (i: any) => i.accountId === current.ownerId)
        .map( (r: any) => ({
          ...r,
          label: `${r.name} (${r.phone})`
        }))
      setOwner(result.length ? result[0] : null)
      setSelect({owner: result.length ? result[0] : null})
    }
  }, [current, owners])


  const handleClose = () => showWindow('bind_owner', false);
  const handleSelect = () => showWinUsers('list_users', true);
  const onSelectedOwner = (value: any) => {
    if(value) {
      value.label = `${value.name} ${value.surname ? value.surname : ''} (tel:${value.phone ?? '-' })`
    
      setOwner(value)
      setSelect({owner: value})
    }
  }; 
  const onSubmit = ( values: any) => bindOwner(values.owner)

  return (
    <>
      <Dialog header="Назначить владельца" visible={visible} style={{minWidth: '383px'}} onHide={handleClose}>
        <Form
          onSubmit={onSubmit}
          initialValues={select}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <div className="mt-2 mb-2">
                <div className="p-inputgroup">
                  <div style={{ width: '100%'}}>
                    <Field
                      validate={composeValidators(requiredValidator)}
                      name="owner.label"
                      label="Владелец"
                      render={TextAreaField}
                      settings={{
                        disabled: true,
                      }}
                    />
                  </div>
                  <Button icon="pi pi-search-plus" className="p-button-success" onClick={(e) => {
                    e.preventDefault();
                    handleSelect();
                  }} />
                </div>
              </div>
              
              <div className="mt-1 flex justify-content-end">
                <Button label="Применить" />
              </div>
            </form>
          )}
        /> 

      </Dialog>

      {visible ? <ListUserWindows type={'owners'} onSubmit={onSelectedOwner} defaultValue={owner} /> : <></>}
      
    </>
  );
}

const mapStateToProps = (state: any) => ({
  current: state.restaurants.current.restaurant,
  owners: state.users.owners,
  visible: state.restaurants.windows.bind_owner
})

export default connect(mapStateToProps, { showWindow, bindOwner, showWinUsers })(BindOwnerDialog);